import axios from "axios";
import cookies from "@/utils/cookie";
import { Message } from "element-ui";
import router from '@/router/index.js'
// 创建一个错误
function errorCreate(msg) {
  const error = new Error(msg);
  throw error;
}
// api本地开发接口地址
// const api = "http://192.168.70.86:8000";
const api = "https://sysapi.ixlzj.com";//线上接口地址

// 创建一个 axios 实例
const service = axios.create({
  baseURL:
    process.env.NODE_ENV == "development"
      ? api
      : "https://sysapi.ixlzj.com",
  // timeout: 5000  //请求超时时间
});

// 是否正在刷新token的标记
let isRefreshing = false;
// 重试队列，每一项将是一个待执行的函数形式
let retryRequests = [];

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在请求发送之前做一些处理
    const token = `Bearer ${cookies.get("token")}`;
    if (cookies.get("token")) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    // 发送失败
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  async (response) => {
    // console.log("response", response);
    //doms2防止重复弹出错误提示
    var doms2 = document.getElementsByClassName("el-message")[0];
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data;
    // 这个状态码是和后端约定的
    const code = dataAxios.code;
    if (code == 200) {
      return dataAxios;
    } else {
      // 其他状态码
      switch (code) {
        // 未登录
        case 496:
          if (doms2 == undefined) {
            Message.error({
              message: "未登录",
            });
          }
          break;
        default:
          // 其他错误
          if (doms2 == undefined) {
            Message.error({
              message: dataAxios.message,
            });
          }
          errorCreate(`${dataAxios.message}: ${response.config.url}`);
          break;
      }
      errorCreate(
        `错误抛出[ code: ${code} ] ${dataAxios.message}: ${response.config.url}`
      );
    }
  },
  //错误状态码
  async (error) => {
    var response = error.response;
    //doms2防止重复弹出错误提示
    var doms2 = document.getElementsByClassName("el-message")[0];
    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          cookies.remove('token')
          cookies.remove('name')
          cookies.remove('avatar')
          cookies.remove('status')
          router.push('/login')
          if (doms2 == undefined) {
            Message.error({
              message: '登录失效请重新登录！',
            });
          }
          break;
        case 402:
          Message.error({
            message: response.data.msg,
          });
          break;
        // 未登录
        case 496:
          if (doms2 == undefined) {
            Message.error({
              message: "未登录",
            });
          }
          break;
        default:
          //其他错误
          if (doms2 == undefined) {
            Message.error({
              message: response.data.msg,
            });
          }
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default service;
